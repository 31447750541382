import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import GroupList from './grouplist'

export default function Mentionare() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How Does This Work?</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              WDsprint allows competitors to hone their analytical and critical thinking skills for web development exploration. WDsprint fosters a culture of rigorous research and skill-building, focusing on practical application rather than mere memorization or copying

              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          
          <GroupList/>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              
              {/* <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet
                    consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate
                    blanditiis ratione.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua
                    ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien
                    vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                  </span>
                </li>
              </ul> */}
          
              <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">Boundaries</h2>
              <p className="mt-6">
              You are granted full autonomy in their choice of programming languages, frameworks, and tools (leverage your strengths and preferences). The only requirement is that their solution meets the specific needs and specifications outlined by the partnering company.
              </p>
              <p className="mt-6">
              Before the competition commences, the participating companies provide comprehensive briefs detailing their website requirements, desired features, and overall vision. These briefs may also include links to existing websites that serve as sources of inspiration, giving the teams a better understanding of the desired user experience and aesthetic.
              </p>
              <p className="mt-6">
              Once the competition begins, teams create accounts on the WDsprint platform and select the company they wish to partner with for the project. From the moment they join until the final submission deadline, typically spanning 1-2 months, the teams have the opportunity to collaborate, design, and develop their website solutions.
              </p>
              <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">Development</h2>
              <p className="mt-6">
              Throughout the development phase, teams are encouraged to leverage their collective skills, creativity, and problem-solving abilities to craft innovative and user-friendly websites that meet or exceed the company's expectations. Regular check-ins and feedback sessions with mentors and industry professionals are available to guide the teams and ensure they remain on track.
              </p>
              <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">Submission</h2>
              <p className="mt-6">
              Upon completion, the teams submit their final projects, typically in the form of a GitHub repository containing their source code. These submissions undergo rigorous plagiarism checks to ensure the integrity and originality of the work.
              </p>
              <p className="mt-6">
              A judging panel, comprising experienced web developers, tech entrepreneurs, and representatives from WDsprint and the partnering companies, meticulously evaluates each submission. The judging criteria encompass various aspects, including adherence to the brief, functionality, user experience, code quality, and overall innovation.
              </p>
              <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900">Results</h2>
              <p className="mt-6">
              Depending on the available resources and funding, WDsprint aims to recognize and reward the top 1-3 winning teams with monetary prizes or other incentives. This recognition not only celebrates the teams' achievements but also serves as a valuable addition to their portfolios, potentially opening doors to internships, job opportunities, and further collaboration within the tech community.
              </p>
              <p className="mt-6">
              {/* <a class="text-black after:content-['_↗'] ..." href="mailto:wdsprint.contact@gmail.com" target="_blank">Read More in Docs</a>  */}
              </p>
              
           
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
