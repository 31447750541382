import TextBackground from '../images/mainbackground.png'

const links = [
    { name: 'Join Us', href: 'mailto:wdsprint.contact@gmail.com' },
    { name: 'Donate', href: 'mailto:wdsprint.contact@gmail.com' },
    { name: 'General Inquires', href: 'mailto:wdsprint.contact@gmail.com' },
  ]
  // const stats = [
  //   { name: 'Open Press', value: 'Large' },
  //   { name: 'Full-time colleagues', value: '30+' },
  //   { name: 'Hour per week', value: 'Only 1' },
  //   { name: 'support', value: 'Unlimited' },
  // ]
  const stats = [
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
  ]
  
  export default function WorkWithUs() {
    return (
      <div className="relative invert isolate overflow-hidden  py-24 sm:py-32">
        <img
          src={TextBackground}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Work with us</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
            Are you a company or startup interested in philanthropic causes and looking for a website? Contact us for information on being a part of the competition.
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  
