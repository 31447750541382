import React, { useEffect, useState, useRef } from 'react';
import firebase from "firebase/compat/app";
import db from "../firebase";

// const people = [
//     {
//       name: 'Yarn',
//       email: 'EdTech',
//       role: 'Status',
//       imageUrl:
//         'https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/JpQKEpb-QUSP214qfm3zpF3JKuWjKGA1DkD26b.png',
//       lastSeen: 'In Process',
//       lastSeenDateTime: '2023-01-23T13:23Z',
//     },
//     {
//       name: 'AI Hub',
//       email: 'BioTech',
//       role: 'Status',
//       imageUrl:
//         'https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/4jyXwNj-ArydyjBCb5wZwwRCQqyR1e6aRkNLoo.png',
//       lastSeen: null,
//     },
//   ]
  
  export default function GroupList() {

    const [startups, setStartups] = useState([]);

    useEffect(() => {
      db.collection("Startups")
        .onSnapshot((snapshot) => {
          setStartups(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
        });
    }, []);


    return (
      <>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Startups <span><p className="mt-2 text-sm font-semibold">
        <a class="text-black after:content ..." href="mailto:wdsprint.contact@gmail.com" target="_blank">Join the List</a> 
        </p></span></h1>
        <ul role="list" className="divide-y divide-gray-100">
          {startups.map((Startups) => (
            <li key={Startups.data.name2} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <img className="h-12 w-12 flex-none  rounded-full bg-transparent" src={Startups.data.logo} alt="" style={{filter: 'invert(1)'}}/>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{Startups.data.Name}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{Startups.data.name2}</p>
                </div>
              </div>
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">Status</p>
        
                <div className="mt-1 flex items-center gap-x-1.5">
                  {/* <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">Active</p> */}
                  <p className="text-xs leading-5 text-gray-500">{Startups.data.Status}</p>       
                </div>

              </div>
            </li>
          ))}
        </ul>
      </>
    )
  }