import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import wdsprintlogo from '../images/wdsprintbm.png'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, animateScroll as scroll } from "react-scroll";

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function NewHeader() {
  let [isOpen, setIsOpen] = useState(false)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
    setMobileMenuOpen(false)
  }

  function smoothScroll() {
    setMobileMenuOpen(false);
    scroll.scrollTo(document.getElementById("Waitlist").offsetTop, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }


  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5 flex items-center justify-center">
              <img
                className="h-8 w-auto"
                src={wdsprintlogo}
                alt=""
              />
              <span style={{paddingLeft: '5px', fontWeight: '700', fontSize: '17px'}}>WDsprint</span>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a onClick={openModal} className="text-sm font-semibold leading-6 text-gray-900">
              Docs <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5 flex items-center justify-center">
                <img
                  className="h-8 w-auto"
                  src={wdsprintlogo}
                  alt=""
                />
                <span style={{paddingLeft: '5px', fontWeight: '700', fontSize: '17px'}}>WDsprint</span>
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
             
                <div className="mt-5 lg:flex lg:flex-1 lg:justify-end">
                  <br/>
                  <a onClick={openModal} className="text-base font-semibold leading-6 text-gray-900">
                    Docs <span aria-hidden="true">&rarr;</span>
                  </a>
                  <br/>
                  <br/>
                  <a href="https://discord.gg/vW8mcpkhKE" target="_blank" className="text-base font-semibold leading-6 text-gray-900">
                    Join Discord <span aria-hidden="true">&rarr;</span>
                  </a>
                  <br/>
                  <br/>
                  <div >
                    <Link
                      to="Waitlist"
                      smooth={true}
                      duration={800}
                      delay={0}
                      offset={-70}
                      onClick={smoothScroll}
                    >
                      
                      <span className="text-base font-semibold leading-6 text-gray-900">Join Waitlist <span aria-hidden="true">&rarr;</span></span>
                
                    </Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                    </div>
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Currently Building
                    
                  </Dialog.Title>
                  </div>
                
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      WDsprint is new! We are working on creating comprehensive documents for all members of the WDsprint community.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex invert justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      </header>
      

      <div className="relative isolate px-6 pt-14 lg:px-8">
       
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Announcing our next round of funding.{' '}
              <a href="#" className="font-semibold text-indigo-600">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div> */}
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white invert sm:text-6xl">
            The ultimate <br/> <a class="underline white-500">web development</a> competition for high schoolers
            </h1>
            <p className="mt-6 text-lg leading-8 text-white invert">
            Transform startups' visions into dynamic websites, hone your web development skills, compete for prestigious awards.

            </p>
            <div className="hidewhenscreensmall">
              <div className="mt-10 flex items-center justify-center gap-x-6">
                
                <div >
                  <Link
                    to="Waitlist"
                    smooth={true}
                    duration={800}
                    delay={0}
                    offset={-70}
                    onClick={smoothScroll}
                  >
                    <button className="get-notififed mr-4">
                      <span className="Get-Notifed-css-button-move">Join Waitlist</span>
                      <span className="arrow-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </span>
                    </button>
                  </Link>
                </div>
                <div>
                  <a href="https://discord.gg/vW8mcpkhKE" target="_blank">
                    <button className="join-discord mr-4">
                      <span className="join-discord-css-button-move">Join Discord</span>
                      <span className="arrow-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                      </span>
                    </button>
                  </a>
                </div>
                
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}
