import wd1 from "../images/wd1.png"
import wd2 from "../images/wd2.png"
import wd3 from "../images/wd3.png"
import wd4 from "../images/wd4.png"

const features = [
    { name: 'Creativity', description: 
    ' WDsprint encourages participants to push creative boundaries, experiment with cutting-edge tech, explore unconventional approaches, and implement bold ideas. By embracing creativity and calculated risks, you can craft remarkable solutions that stand out and impress judges and the tech community.' 
  },
    { name: 'Skills', description: 'The competition provides a hands-on learning experience to solidify existing web dev skills while expanding knowledge into new technologies and techniques. Participants cement proficiency in familiar tools and foster a growth mindset by exploring new areas, equipping them with a diverse skill set for future endeavors.' },
    { name: 'Portfolio', description: ' Developing real-world projects during WDsprint results in tangible portfolio pieces that showcase skills, creativity, and problem-solving abilities. Whether a grand prize winner or exceptional submission, these websites demonstrate technical prowess, collaborative work, and ability to deliver quality products under tight deadlines.' },
    { name: 'Prestige', description: 'As a national, highly competitive competition involving industry professionals, awards from WDsprint will carry significant prestige. Awards earned serve as testament to your exceptional web dev skills, dedication, and ability to thrive in challenging environments. Awards from WDsprint will capture the attention of college admissions and potential employers.' },
  ]
  
  export default function WhatsForU() {
    return (
      <div className="bg-white">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What's In For Me?</h2>
            <p className="mt-4 text-gray-500">
            WDsprint brings together some of the brightest minds from across the globe, offering you unparalleled networking opportunities with fellow developers, industry leaders, potential collaborators, and so much more.
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div
          // className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          className=" absolute  inset-x-0 -z-1  transform-gpu   blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />

        </div>
         
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">

            <img
              src={wd1}
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="rounded-lg invert"
            />
            <img
              src={wd2}
              alt="Top down view of walnut card tray with embedded magnets and card groove."
              className="rounded-lg invert"
            />
            <img
              src={wd3}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg invert "
            />
            <img
              src={wd4}
              alt="Walnut card tray filled with cards and card angled in dedicated groove."
              className="rounded-lg invert "
            />
          </div>
        </div>
      </div>
    )
  }
  