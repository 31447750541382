
import './App.css';
import Formstuff from './components/formstuff';
import Mentionare from "./components/metionare"
import Nicerwf from "./components/nicerwf"
import trophy from "./images/trophy.png"
import Trustedby from './components/trustedby';
import WhatsForU from './components/whatsforu';
import TextBackground from './images/mainbackground.png'
import db from "./firebase";

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { useRef } from 'react';
import { useState } from 'react';
import WorkWithUs from './components/workwithus';

const stats = [
  { id: 1, name: 'In Prizes', value: '$300+' },
  { id: 2, name: 'Companies Looking for Websites', value: '46+' },
  { id: 3, name: 'On the Waitlist', value: '200+' },
]

function App() {

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const bottomRef = useRef(null); // Create a ref for the bottom element

  const [emailv, setEmailv] = useState('');
  const [comment, setComment] = useState('');

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (e) =>{
    e.preventDefault()
    db.collection("wdsprintinfo").add({
      email: emailv,
      message: comment,
    });
    setComment(" ")
    setEmailv(" ")
    setIsOpen(true)
  }

  return (
    <section className="m-8 mx-8">
     
      {/* PUT BACK WHEN WE HAVE MORE SPONSORS */}
          
      {/* <div data-aos="fade-up" className="space-y-4 ">
        <Trustedby/>
      </div> */}

      <div className="move-up"/>

      
      <hr style={{width: '100%', backgroundColor: 'grey', height: '1px'}}></hr>
      

      <WorkWithUs/>

      <hr style={{width: '100%', backgroundColor: 'grey', height: '1px'}}></hr>
      
      <Mentionare/>
      <WhatsForU/>
        <div id="Waitlist" className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            {/* <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Waitlist</h2> */}
            <h1 className="text-5xl md:text-7xl font-bold tracking-tighter leading-tight md:pr-8">
        Waitlist
      </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Be Reminded When the 2024 WDsprint Competiton is Open for Registration
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Email</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
              This information will never be shared publicly.
              </p>
              <div className="sm:col-span-4">
              
              <div className="mt-6">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                 
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={emailv}
                    autoComplete="username"
                    onChange={(e) => {setEmailv(e.target.value)}}
                    className="block flex-1 pl-2 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Email"
                    style={{padding: '8px', fontSize: '15px'}}
                    maxLength={30}
                    minLength={7}
                  />
                </div>
              </div>
            </div>

              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-black">Comment/Question</h4>
                <div className="h-px flex-auto bg-gray-100" />
                
              </div>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  onChange={(e) => {setComment(e.target.value)}}
                  value={comment}
                  className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={' '}
                  maxLength={250}
                  minLength={20}
                  style={{resize: 'none', fontSize: '16px', height: '12.2vh', padding: '10px'}}
                />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
              
              </ul>
            </div>
            <div ref={bottomRef} className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">2024 WDsprint Competiton</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">July '24</span>                  </p>
   
                    <button 
                      disabled={!emailv || emailv.length <= 6}
                      onClick={handleSubmit}
                      className="mt-10 block w-full rounded-md bg-black px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
                      Get Notified
                    </button>
      
                  <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black/25" />
                      </Transition.Child>

                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              
                           
                                  <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Joined Waitlist
                                  
                                </Dialog.Title>
                                
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Welcome to the WDsprint community. You will now be notifed on the 2024 competition, and all other updates!
                                </p>
                              </div>

                              <div className="mt-4">
                                <button
                                  type="button"
                                  className="inline-flex invert justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={closeModal}
                                >
                                  Got it, thanks!
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Subject to change in the future should there be significant adjustments to the WDsprints plans.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Formstuff/> */}
      </section>
   
  );
}

export default App;
